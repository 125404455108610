import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import React, { useState } from 'react';
import { IconAlert } from '../../../svg/Icons/IconAlert';
import { IconClose } from '../../../svg/Icons/IconClose';

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    backgroundColor: theme.palette.error.main,
    width: 400,
    borderRadius: 16,
    padding: '14px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  minorErrorAlert: {
    backgroundColor: theme.palette.error.minor,
    width: 400,
    borderRadius: 16,
    padding: '14px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  successAlert: {
    backgroundColor: theme.palette.success.main,
    width: 400,
    borderRadius: 16,
    padding: '14px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  title: {
    margin: 0,
    fontSize: '1rem',
    color: theme.palette.white,
    fontWeight: '800',
  },
  text: {
    margin: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    justifyContent: 'flex-start',
  },
  texts: {
    marginLeft: 16,
  },
}));

const anchorOrigin = { vertical: 'top', horizontal: 'center' };

export default function AlertSnackbar(props) {
  const classes = useStyles();
  const { title, text, isError, isMinorError, onClose } = props;
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={anchorOrigin}>
      <div className={isError ? classes.errorAlert : isMinorError ? classes.minorErrorAlert : classes.successAlert}>
        <div className={classes.content}>
          <IconAlert width={32} height={32} isError={isError} />
          <div className={classes.texts}>
            <p className={classes.title}>{title}</p>
            <p className={classes.text}>{text}</p>
          </div>
        </div>
        <IconButton size="small" onClick={handleClose}>
          <IconClose width={24} height={24} />
        </IconButton>
      </div>
    </Snackbar>
  );
}
