const colors = {
  white: '#FFF',
  black: '#000',
  primary: { main: '#0d5ebd', light: '#167EF8' },
  secondary: { main: '#16eff8', dark: '#006468' },
  success: { main: '#08dea1', dark: '#04b382' },
  error: { main: '#E5314F', minor: '#ffc500' },
  info: { main: '#16eff8' },
  warning: { main: '#ffc500' },
  dark: {
    text: {
      primary: '#FFF',
      secondary: '#cac9cf',
      disabled: '#6b6a6f',
      hint: '#9a99a1',
      success: '#08dea1',
      notActive: '#9A95B8',
      placeholder: '#4B4A4D',
    },
    background: {
      paper: '#242229',
      default: '#1e1c23',
      paperSecondary: '#312f38',
      paperThird: '#3C3943',
      paperForm: '#28262D',
      label: '#212027',
      input: '#1E1C22',
      context: '#3F3B48',
    },
    divider: '#121115',
    dividerSecondary: '#3a383e',
    action: {
      hover: 'rgba(0, 0, 0, 0.2)',
      hoverSecondary: 'rgba(255, 255, 255, 0.08)',
      tableSelected: '#272737',
    },
    table: {
      tHeadBg: '#2a2830',
      tHeadText: '#55525a',
    },
    outlinedBtn: 'rgba(22, 126, 248, 0.2)',
  },
};

export default colors;
